


















































import {
  computed,
  defineComponent, PropType, useCssModule,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { removeObjectEmptyFields } from '~/helpers/removeObjectEmptyFields';
import { YieldsInterface } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'YieldsList',

  components: {
    SvgImage,
  },

  props: {
    yields: {
      type: [Array, null] as PropType<YieldsInterface[] | null>,
      default: () => [],
    },
    isMaintenance: {
      type: Boolean,
      default: false,
    },
    isListing: {
      type: Boolean,
      default: false,
    },
    propsStyles: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const defaultStyles = useCssModule();

    const computedStyles = computed(() => ({ ...defaultStyles, ...removeObjectEmptyFields(props.propsStyles) }));
    const isPhotosYields = (yieldItem) => (yieldItem?.yield_text ? yieldItem.yield_text.indexOf('photos (') > 0 : false);
    const getYieldTextForMobile = (yieldText = '') => {
      const yieldTextType = yieldText.split(' ');

      if (yieldTextType?.[1] === 'pages') {
        return yieldTextType[0];
      }

      return yieldText;
    };

    return {
      computedStyles,
      isPhotosYields,
      getYieldTextForMobile,
    };
  },
});

