































































































import {
  computed,
  defineComponent, PropType,
} from '@nuxtjs/composition-api';

import { MODAL_NAMES } from '~/components/common/SimpleModal';
import Tooltip from '~/components/common/Tooltip.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { ProductPackPropsInterface } from '~/components/Product/types';
import { useUiState } from '~/composables';
import isServer from '~/helpers/isServer';
import { isGenuine } from '~/helpers/productHelpers';
import { ProductInterface } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'YieldsHead',

  components: {
    SvgImage,
    Tooltip,
  },

  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface | null>,
      required: true,
    },
    bestValueType: {
      type: String,
      default: null,
    },
    perPrintType: {
      type: String,
      default: '',
    },
    yieldsLength: {
      type: Number,
      default: 0,
    },
    yieldsType: {
      type: String,
      default: '',
    },
    modalProps: {
      type: Object as PropType<ProductPackPropsInterface>,
      default: null,
    },
  },

  setup(props) {
    const { toggleModal } = useUiState();
    const isProductGenuine = computed<boolean>(() => isGenuine(props.product));

    const openPackModal = () => {
      if (!props.modalProps.yields) {
        return;
      }

      toggleModal({
        identifier: MODAL_NAMES.productPack,
        props: {
          productPack: props.modalProps,
        },
      });
    };

    return {
      isProductGenuine,
      isServer,
      openPackModal,
    };
  },
});

