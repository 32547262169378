















































import {
  computed, defineComponent, PropType,
} from '@nuxtjs/composition-api';

import AddToCart from '~/components/AddToCart/AddToCart.vue';
import { MODAL_NAMES } from '~/components/common/SimpleModal';
import { useUiState } from '~/composables';
import { ProductInterface } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'ProductStock',

  components: {
    AddToCart,
  },

  props: {
    product: {
      type: Object as PropType<ProductInterface>,
      required: true,
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
    isListing: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { toggleModal } = useUiState();

    const stock = computed(() => props.product?.stock);
    const isNewToMarket = computed(() => props.product.new_to_market);
    const isInStock = computed(() => stock.value.is_in_stock && stock.value.qty > 0 && !isNewToMarket.value);
    const dueInDate = computed(() => {
      let date = '';

      if (props.product.due_in_date) {
        const dueIn = new Date(props.product.due_in_date);
        const mToday = Date.now();

        if (dueIn.getTime() === mToday || dueIn.getTime() > mToday) {
          date = dueIn.toLocaleDateString('en-GB');
        }
      }

      return date;
    });

    const openModal = () => {
      toggleModal({
        identifier: MODAL_NAMES.outOfStock,
        props: {
          product: props.product,
        },
      });
    };

    return {
      isNewToMarket,
      isInStock,
      dueInDate,
      openModal,
    };
  },
});
