




































import { defineComponent, PropType } from '@nuxtjs/composition-api';

import { useTax } from '~/composables';
import { TierPrice } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'TierPrices',
  props: {
    tierPrices: {
      type: Array as PropType<TierPrice[]>,
      required: true,
      default: () => [],
    },
    isProductPage: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { taxState, vatLabel } = useTax();

    return {
      vatLabel,
      taxState,
    };
  },
});
