































































































































import {
  computed,
  defineComponent, PropType,
} from '@nuxtjs/composition-api';

import { SimpleImage } from '~/components/common/SimpleImage';
import { MODAL_NAMES } from '~/components/common/SimpleModal';
import CompatibleSaves from '~/components/CompatibleSaves.vue';
import ProductDelivery from '~/components/Delivery.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import ProductPrice from '~/components/Price.vue';
import ListingBullets from '~/components/ProductBullets/ListingType/ListingType.vue';
import PrinterBullets from '~/components/ProductBullets/PrinterType/PrinterType.vue';
import ReviewsBar from '~/components/ReviewsBar.vue';
import ProductStock from '~/components/Stock/Stock.vue';
import SwitchAndSaveLabel from '~/components/SwitchAndSave/components/Label.vue';
import TierPrices from '~/components/TierPrices/TierPrices.vue';
import Yields from '~/components/Yields/Yields.vue';
import { useCompatibleTitle, usePageHelpers, useUiState } from '~/composables';
import {
  isGenuine, isPrinter,
} from '~/helpers/productHelpers';
import { ProductInterface } from '~/modules/GraphQL/types';

import ProductTileName from './components/Name.vue';

export default defineComponent({
  name: 'ProductTile',
  components: {
    ProductTileName,
    ReviewsBar,
    ListingBullets,
    PrinterBullets,
    CompatibleSaves,
    SimpleImage,
    SwitchAndSaveLabel,
    Yields,
    ProductDelivery,
    ProductPrice,
    ProductStock,
    SvgImage,
    TierPrices,
  },
  props: {
    product: {
      type: Object as PropType<ProductInterface>,
      required: true,
    },
    isCrossSell: {
      type: Boolean,
      default: false,
    },
    isOnlyImage: {
      type: Boolean,
      default: false,
    },
    bestValues: {
      type: Object,
      default: () => ({}),
    },
    isListingPage: {
      type: Boolean,
      default: false,
    },
    noLazyLoad: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isPrinterPage, isSearchPage } = usePageHelpers();
    const { toggleModal } = useUiState();
    const { compatibleTitle } = useCompatibleTitle(props.product);

    const isProductGenuine = computed(() => isGenuine(props.product));
    const isListingProduct = computed<boolean>(() => props.isListingPage || isPrinter(props.product));
    const isPrinterProduct = computed<boolean>(() => isPrinter(props.product));

    const currentRating = computed(() => (!props.product.rating_summary ? 0 : +((props.product.rating_summary * 5) / 100).toFixed(1)));
    const reviewsTotal = computed(() => props.product.review_count);
    const isListing = computed(() => isPrinterPage.value || isSearchPage.value);

    const openNewVisibilityModal = () => {
      toggleModal({
        identifier: MODAL_NAMES.newVisibility,
        props: {
          product: props.product,
        },
      });
    };

    return {
      isProductGenuine,
      isListingProduct,
      isListing,
      compatibleTitle,
      currentRating,
      reviewsTotal,
      isSearchPage,
      isPrinterProduct,
      openNewVisibilityModal,
    };
  },
});
