import { computed } from '@nuxtjs/composition-api';

import { StockInfoType, StockInfoTypeOutput } from './types';

const MORE_THAN_MAX_QTY = '99+';

export const useStockInfo = ({ specialStock, stock }: StockInfoType): StockInfoTypeOutput => {
  const deliveryText = computed(() => {
    if (stock?.delivery_text) {
      return stock.delivery_text;
    }

    if (!specialStock) return 'Order before 5:15pm for dispatch today';

    return specialStock.delivery_text;
  });

  const specialStockConfig = computed(() => ({
    isSpecialDelivery: specialStock?.is_special_delivery || false,
    isChristmasTime: specialStock?.is_christmas_time || false,
    canShowEstimate: specialStock?.can_show_estimate || false,
    hasDeliveryMessage: (!(specialStock?.is_christmas_time || specialStock?.is_special_delivery) && deliveryText) || false,
  }));

  const stockLevel = computed(() => {
    if (Object.keys(stock).length === 0) {
      return 0;
    }

    return stock.qty > 99 ? MORE_THAN_MAX_QTY : stock.qty;
  });

  return {
    stockLevel,
    specialStockConfig,
  };
};

export default useStockInfo;
