






























































import {
  computed,
  defineComponent, PropType, useCssModule,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { ProductPackPropsInterface } from '~/components/Product/types';
import { isHighCapacity, isMaintenance } from '~/helpers/productHelpers';
import { removeObjectEmptyFields } from '~/helpers/removeObjectEmptyFields';
import { ProductInterface } from '~/modules/GraphQL/types';

import YieldsHead from './Head/Head.vue';
import YieldsList from './List/List.vue';

export default defineComponent({
  name: 'Yields',

  components: {
    YieldsHead,
    YieldsList,
    SvgImage,
  },

  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface | null>,
      required: true,
    },
    bestValueType: {
      type: String,
      default: null,
    },
    yieldsType: {
      type: String,
      default: '',
    },
    isListing: {
      type: Boolean,
      default: false,
    },
    propsStyles: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const defaultStyles = useCssModule();

    const computedStyles = computed(() => ({ ...defaultStyles, ...removeObjectEmptyFields(props.propsStyles) }));
    const perPrintType = computed<string>(() => {
      if (!(props.product.per_page_price && props.product.per_print_type)) return '';

      return props.product.per_print_type;
    });
    const capacity = computed(() => {
      let result = [];

      if (props.product?.capacity?.length) {
        result = props.product.capacity.split(', ');
      }

      return result;
    });
    const yields = computed(() => props.product.yields);

    const hasYields = computed<boolean>(() => capacity.value.length > 0 || !!yields.value);
    const hasPages = computed<boolean>(() => !!(hasYields.value || perPrintType.value));
    const isProductHighCapacity = computed<boolean>(() => isHighCapacity(props.product));
    const isProductMaintenance = computed<boolean>(() => isMaintenance(props.product.group));
    const productPackModalProps = computed<ProductPackPropsInterface>(() => ({
      yields: yields.value,
      capacity: capacity.value,
      isMaintenance: isProductMaintenance.value,
    }));

    return {
      computedStyles,
      perPrintType,
      yields,
      capacity,
      hasYields,
      hasPages,
      isProductHighCapacity,
      isProductMaintenance,
      productPackModalProps,
    };
  },
});

