


























import {
  computed,
  defineComponent, PropType,
} from '@nuxtjs/composition-api';

import { MODAL_NAMES } from '~/components/common/SimpleModal';
import { useUiState } from '~/composables';
import {
  getProductPath,
} from '~/helpers/productHelpers';
import { ProductInterface } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'ProductTileName',

  props: {
    product: {
      type: Object as PropType<ProductInterface>,
      required: true,
    },
    isOnlyImage: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { toggleModal } = useUiState();

    const isOnSale = computed(() => ([true, '1', 1].includes(props.product.sale) ? 'sale' : ''));
    const isNew = computed(() => ([true, '1', 1].includes(props.product.new) ? 'new' : ''));

    const openNewVisibilityModal = () => {
      toggleModal({
        identifier: MODAL_NAMES.newVisibility,
        props: {
          product: props.product,
        },
      });
    };

    return {
      isOnSale,
      isNew,
      openNewVisibilityModal,
      getProductPath,
    };
  },
});
