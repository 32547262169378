























import {
  computed,
  defineComponent, PropType,
} from '@nuxtjs/composition-api';

import { ProductInterface } from '~/composables';
import useShipmentDelivery from '~/composables/useShipmentDelivery';
import { FREE_DELIVERY_AMOUNT } from '~/constants';
import isServer from '~/helpers/isServer';
import { useCmsBlocksStore } from '~/stores/cmsBlocks';

export default defineComponent({
  name: 'ProductDelivery',
  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface | null>,
      default: null,
    },
    isListing: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { getCmsBlockByIdentifier } = useCmsBlocksStore();
    const { dispatchTextToday } = useShipmentDelivery('16,15' || props.product.delivery_cut_off);

    const showNextDayText = computed(() => {
      const specialStock = props.product.special_stock;

      if (!specialStock || specialStock.is_special_delivery) {
        return false;
      }

      return !(specialStock.is_christmas_time);
    });
    const cmsContent = computed(() => getCmsBlockByIdentifier('delivery_price_footer'));
    const defaultMessageId = computed(() => (props.product.price?.regularPrice?.amount?.value >= FREE_DELIVERY_AMOUNT ? 'FREE next-day delivery' : 'Next-day delivery'));
    const specialMessage = computed(() => (props.product.price?.regularPrice?.amount?.value >= FREE_DELIVERY_AMOUNT ? 'FREE delivery' : ''));
    // eslint-disable-next-line max-len
    const cutOffText = computed(() => props.product.special_stock?.can_show_estimate && props.product.price?.regularPrice?.amount?.value >= FREE_DELIVERY_AMOUNT && dispatchTextToday.value);

    return {
      showNextDayText,
      cmsContent,
      defaultMessageId,
      specialMessage,
      cutOffText,
      isServer,
    };
  },
});
