

















import { defineComponent } from '@nuxtjs/composition-api';

import RatingStars from '~/components/RatingStars/RatingStars.vue';
import { useProductPageState } from '~/composables';
import { PRODUCT_TABS } from '~/composables/useProductTabs';
import { smoothScrollToElement } from '~/helpers/smoothScrollToElement';

export default defineComponent({
  name: 'ReviewsBar',

  components: {
    RatingStars,
  },

  props: {
    rating: {
      type: [String, Number],
      required: true,
      default: 0,
    },
    count: {
      type: [String, Number],
      required: true,
      default: 0,
    },
    isListing: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { setActiveTab } = useProductPageState();

    const showReviews = () => {
      if (props.isListing) {
        return;
      }

      const reviewsTabId = props.count > 0 ? PRODUCT_TABS.reviews : PRODUCT_TABS.emptyReviews;

      setActiveTab(reviewsTabId);
      smoothScrollToElement(`#${reviewsTabId}`);
    };

    return {
      showReviews,
    };
  },
});
