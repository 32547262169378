




import {
  defineComponent,
} from '@nuxtjs/composition-api';

import { getFullUrl, updateURLParam } from '~/helpers/urlHelpers';

export default defineComponent({
  name: 'PrintersSeoTitle',

  props: {
    type: {
      type: String,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  // @ts-ignore
  head() {
    const title = this.page === 1 ? `${this.type}` : `${this.page} of ${this.type}`;

    return {
      title,
      ...(this.type === 'Printers' ? {} : {
        meta: [{
          hid: 'description',
          name: 'description',
        }],
      }),
      link: [{
        hid: 'canonical',
        rel: 'canonical',
        href: updateURLParam(getFullUrl(this.$route.path), 'p', (this.page === 1 ? 0 : this.page) as number),
      }],
    };
  },
});

