




















































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import useShipmentDelivery from '~/composables/useShipmentDelivery';
import { ProductInterface } from '~/modules/GraphQL/types';

import useStockInfo from './useStockInfo';

export default defineComponent({
  name: 'StockInfo',
  components: {
    SvgImage,
  },
  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface>,
      required: true,
    },
    isListing: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const specialStock = computed(() => props.product.special_stock || null);
    const stock = computed(() => props.product.stock || null);

    const { stockLevel, specialStockConfig } = useStockInfo({
      specialStock: specialStock.value,
      stock: stock.value,
    });

    const { dispatchTextToday, productDeliveryText } = useShipmentDelivery('16,15' || props.product.delivery_cut_off);
    const deliveryLeadTimes = computed(() => {
      const stockValue = specialStock.value;

      return !stockValue ? '10-14' : `${stockValue.delivery_lead_times.from}-${stockValue.delivery_lead_times.to}`;
    });
    const isCutOffText = computed(() => specialStockConfig.value?.canShowEstimate && dispatchTextToday.value);

    return {
      specialStockConfig,
      stockLevel,
      deliveryLeadTimes,
      productDeliveryText,
      isCutOffText,
    };
  },
});
