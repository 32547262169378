




import {
  defineComponent, useMeta,
} from '@nuxtjs/composition-api';

import ProductsListing from '~/components/ProductsListing/ProductsListing.vue';

export default defineComponent({
  name: 'SearchPage',
  components: {
    ProductsListing,
  },
  middleware: [
    'page-preload-resolver',
  ],
  setup() {
    useMeta({
      bodyAttrs: {
        class: 'overflow-x-visible md_overflow-x-hidden',
      },
    });
  },
  head() {
    return {
      title: 'Cartridge Save - Search',
    };
  },
});
