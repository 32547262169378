



























import {
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductQuantity',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    maxValue: {
      type: Number,
      default: 10,
    },
    selected: {
      type: Number,
      default: 1,
    },
    modelValue: {
      type: String,
      default: '1',
    },
    value: {
      type: String,
      default: '1',
    },
    isListing: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(_, { emit }) {
    const onInput = (value: string) => {
      emit('update:modelValue', value);
      emit('input', value);
    };

    return {
      onInput,
    };
  },
});
