



















































import {
  computed, defineComponent, PropType, ref, watch,
} from '@nuxtjs/composition-api';
import { SfAlert } from '@storefront-ui/vue';

import SimpleButton from '~/components/common/SimpleButton.vue';
import { MODAL_NAMES } from '~/components/common/SimpleModal';
import { useCart, useUiState } from '~/composables';
import {
  ADD_TO_CART_QTY, APP_ROUTER_PATHS, AVAILABLE_PRODUCT_STATUS, AVAILABLE_PRODUCT_VISIBILITY,
} from '~/constants';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { isGenuine, isHP } from '~/helpers/productHelpers';
import { ProductInterface } from '~/modules/GraphQL/types';

import ProductQuantity from './components/Quantity.vue';
import StockInfo from './components/StockInfo/StockInfo.vue';

export default defineComponent({
  name: 'AddToCart',
  components: {
    StockInfo,
    SimpleButton,
    ProductQuantity,
    SfAlert,
  },
  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface | null>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isListing: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const productQty = ref<string>(ADD_TO_CART_QTY);
    const buttonLabel = ref<string>('Add to Basket');
    const isAddingItem = ref<boolean>(false);

    const { getQtyInCart } = useAddToCart();
    const {
      addItem, error: cartError, loading: isCartLoading, canAddToCart,
    } = useCart();
    const { toggleModal } = useUiState();

    const addToCartError = computed(() => cartError.value?.addItem?.message);
    const loadCartError = computed(() => cartError.value?.load?.message);
    const qtyInCart = computed(() => getQtyInCart(props.product) || 0);

    const isProductDisabled = computed(() => !!isCartLoading.value || props.disabled || loadCartError.value || isAddingItem.value);
    const isAvailableQty = computed(() => canAddToCart(props.product, +productQty.value));
    const isAltProduct = computed(() => {
      const altProduct = props.product.alternative_product;

      if (altProduct) {
        const isVisible = AVAILABLE_PRODUCT_VISIBILITY.includes(+altProduct.visibility);
        const isStatus = AVAILABLE_PRODUCT_STATUS.includes(+altProduct.status);

        return isVisible && isStatus && !altProduct.new_to_market && altProduct.stock?.is_in_stock;
      }

      return false;
    });
    const maxSaleQty = computed(() => props.product?.stock?.max_sale_qty || 10);

    const handleAddToCart = async () => {
      isAddingItem.value = true;
      await addItem({ product: props.product, quantity: +productQty.value, customQuery: { addProductsToCart: 'addProductsToCart' } });

      isAddingItem.value = false;

      if (isAltProduct.value && isGenuine(props.product) && !isHP(props.product)) {
        toggleModal({
          identifier: MODAL_NAMES.switchAndSell,
          props: {
            product: props.product,
            qty: +productQty.value,
          },
        });
        return;
      }

      toggleModal({
        identifier: MODAL_NAMES.afterAddToCart,
        props: {
          product: props.product,
          qty: +productQty.value,
        },
      });
    };

    watch(isCartLoading, () => {
      if (!isCartLoading.value) {
        buttonLabel.value = 'Added';

        setTimeout(() => {
          buttonLabel.value = 'Add to Basket';
        }, 1000);
      } else {
        buttonLabel.value = 'Adding...';
      }
    });

    return {
      isProductDisabled,
      isAvailableQty,
      qtyInCart,
      buttonLabel,
      productQty,
      addToCartError,
      maxSaleQty,
      checkoutLink: APP_ROUTER_PATHS.contact,
      handleAddToCart,
    };
  },
});
